const DeduplicationFilter = {
	name: "deduplication",
	type: "frequency",
	title: "Deduplication",
	description: "Detail how often you want to deliver the same data point.",
	value: "custom",
	options: [
		{
			label: `Include all Data Points`,
			value: 'default',
		},
		{
			label: 'Custom',
			value: 'custom',
		}
	],
	customOption: {
		heading: 'Deduplication Strategy',
		config: {
      text: 'Deliver once every',
			periodOptions: [
				{
					label: 'Day(s)',
					value: 'D'
				}
			]  
		},
		value: ['30', 'D'],
		supportingOption: {
			config: {
        text: 'Only deliver a unique record once per period for the selected combination of data points',
        selectLabel: 'Data Points',
				items: []
			},
			value: null
		}
	}
}

export default DeduplicationFilter

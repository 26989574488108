import { baseUrl, getHeaders } from '@/utils/ttdServiceLayer'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

let makeDestinations = (dataStream, offers) => {
  const destinations = []

  offers.filter(offer => offer.active).forEach(offer => {
    switch (offer.name) {
      case 'Narrative TTD 3p Custom Connector':
        const destination = {
          apiUrl: 'data-shops/subscriptions/materialize',
          createFnPromise: makeTTDDestination,
          payload: {
            profileId: offer.profileId,
            dataStreamId: dataStream.id,
            parentElementId: offer.value.selectedTaxonomyElement.id,
            dataStreamName: dataStream.name,
            dataStreamDescription: dataStream.content ? dataStream.content.description : null,
            offerValue: offer.value
          }
        }
        destinations.push(destination)
        break;
      default:
        break;
    }
  })
  return destinations
}

let makeTTDDestination = async ({profileId, dataStreamId, parentElementId, dataStreamName, dataStreamDescription, offerValue}, openApi) => {
  const providerElementId = uuidv4()
  try {
    const createResp = await createTaxonomyItem({parentElementId, dataStreamName, dataStreamDescription, providerElementId})
  } catch (error) {
    return Promise.reject(error)
  }
  try {
    const [materializeResp, ratesResp] = await Promise.all([
      materializeDataStream({profileId, dataStreamId, providerElementId}, openApi),
      syncTaxonomyRateDetails(offerValue.rateCard, providerElementId)
    ])
    if (materializeResp?.status === 200 && (!ratesResp || ratesResp?.status === 200)) {
      return Promise.resolve()
    } else {
      return Promise.reject({
        materializeResp,
        ratesResp
      })
    }
  } catch (error) {
    console.log(error)
    return Promise.reject(error)
  }
}

let createTaxonomyItem = async ({parentElementId, dataStreamName, dataStreamDescription, providerElementId}) => {
  const reqBody = {
    provider_element_id: providerElementId,
    parent_element_id: parentElementId,
    display_name: dataStreamName,
    buyable: true,
    description: dataStreamDescription
  }
  try {
    const resp = await axios.post(`${baseUrl}/taxonomy`, reqBody, getHeaders())
    if (resp?.status === 200) {
      return Promise.resolve(resp.data)
    } else {
      return Promise.reject(resp)
    }
  } catch (error) {
    console.log(error)
    return Promise.reject(error)
  }
}

let materializeDataStream = async ({profileId, dataStreamId, providerElementId}, openApi) => {
  const reqBody = {
    data_stream_id: dataStreamId,
    cadence: "weekly", // TODO
    destinations: [
      {
        profile_id: profileId,
        quick_settings: {
          use_existing_taxonomy_elements : [providerElementId]
        }
      }
    ]
  }
  try {
    const resp = await openApi.post('/data-shops/subscriptions/materialize', reqBody, getHeaders())
    if (resp?.status === 200) {
      return Promise.resolve(resp)
    } else {
      return Promise.reject(resp)
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

let syncTaxonomyRateDetails = async (rateCard, providerElementId) => {
  if (rateCard.type === 'inherited') return Promise.resolve()
  const dataRates = []
  if (rateCard.type === 'system') {
    dataRates.push(makeAdvertiserPartnerRateCard(rateCard, providerElementId, null, 'System'))
  } else if (rateCard.type === 'advertiserPartner') {
    if (rateCard.partner.active) {
      if (rateCard.partner.ids.length > 0) {
        const partnerIds = rateCard.partner.ids.split('\n')
        partnerIds.forEach(id => {
          dataRates.push(makeAdvertiserPartnerRateCard(rateCard, providerElementId, id, 'Partner'))
        })
      }
    }
    if (rateCard.advertiser.active) {
      if (rateCard.advertiser.ids.length > 0) {
        const advertiserIds = rateCard.advertiser.ids.split('\n')
        advertiserIds.forEach(id => {
          dataRates.push(makeAdvertiserPartnerRateCard(rateCard, providerElementId, id, 'Advertiser'))
        })
      }
    }
  }
  try {
    if (dataRates.length > 0) {
      const dataRatesResp = await axios.post(`${baseUrl}/data-rates/batches`, { data_rates: dataRates }, getHeaders())
      if (dataRatesResp?.status === 200) {
        return Promise.resolve(dataRatesResp)
      } else {
        return Promise.reject()
      }
    } else {
      return Promise.resolve()
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

let makeAdvertiserPartnerRateCard = (rateCard, providerElementId, id, type) => {
  return {
    provider_element_id: providerElementId,
    rate_level: type,
    partner_id: type === 'Partner' ? id : null,
    advertiser_id: type === 'Advertiser' ? id : null,
    rate_type: "Hybrid",
    percent_of_media_cost_rate: rateCard[type.toLowerCase()].revenueShare / 100,
    cpm_rate: {
      amount: parseFloat(rateCard[type.toLowerCase()].cpmCap),
      currency_code: "USD",
    }
  }
}

let ttdConnectorSummary = (offer) => {
  const type = offer?.value?.rateCard?.type
  if (!offer.valid) return ''
  if (type) {
    switch (type) {
      case 'inherited':
        return 'Rate card inherited from parent'
      case 'system':
        return 'Open Market rate card'
      case 'advertiserPartner':   
        return 'Advertiser/Partner rate card'
      default:
        return ''
    }
  }
  return ''
}

export {
  makeDestinations,
  ttdConnectorSummary
}
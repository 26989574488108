<template lang="pug">
  .columns-step
    .header.step-header
      h2.nio-h2.text-primary-darker Choose Columns
      p.nio-p.text-primary-dark Selected columns will be used to define your data stream. All fields are checked by default.
    .step-loading(v-if="loading")
      v-progress-circular.progress(size="80" indeterminate color="#1438F5")
    .columns
      NioSlatTable(
        v-if="tableColumns && items"
        :key="dataset.id"
        :items="items"
        :columns="tableColumns"
        :searchable-props="['name', 'description']"
        action="custom"
        search-header
      )
        template(v-slot:custom-header-element)
          .select-all
            NioSwitch(
              v-model="allDeliverableModel"
              @change="allDeliverableUpdated($event)"
            )
            .nio-p-small.text-primary-dark Deliverable
            NioSwitch(
              v-model="allFilterableModel"
              @change="allFilterableUpdated($event)"
            ) 
            .nio-p-small.text-primary-dark Filterable
        template(v-slot:custom-action="slotProps")
          NioSwitch(
            v-model="deliverableModel[slotProps.item.id - 1]"
            @update="deliverableUpdated(slotProps.item, $event)"
          )
          .nio-p-small.text-primary-dark Deliverable
          NioSwitch(
            v-model="filterableModel[slotProps.item.id - 1]"
            @update="filterableUpdated(slotProps.item, $event)"
          ) 
          .nio-p-small.text-primary-dark Filterable
      .invalid.nio-p.text-error(v-if="invalid") At least one column is required
</template>

<script>

import * as Dataset from '@/modules/dataset'
import Vue from 'vue'

export default {
  props: {
    "filterable": { type: Array, required: false, default: [] },
    "deliverable": { type: Array, required: false, default: [] },
    "dataset": { type: Object, required: false },
    "completedSummary": { type: Object, required: false } 
  },
  data: () => ({
    loading: true,
    items: null,
    filterableModel: [],
    deliverableModel: [],
    allDeliverableModel: false,
    allFilterableModel: false,
    tableColumns: [
      {
        name: "slat",
        props: {
          title: 'name',
          subtitle: 'description'
        }
      }
    ]  
  }),
  computed: {
    invalid() {
      return this.deliverableModel.length < 1
    }
  },
  watch: {
    dataset(val) {
      if (this.dataset) {
        this.computeItems()
      }
    },
    deliverable(val) {
      this.deliverableModel.forEach((enabled, index) => {
        const itemAtIndex = this.items.find(item => item.id === index + 1)
        if (this.deliverable.find(deliverableColumn => deliverableColumn.name === itemAtIndex.name) !== undefined) {
          Vue.set(this.deliverableModel, index, true)
        } else {
          Vue.set(this.deliverableModel, index, false)
        }
      })
      this.allDeliverableModel = this.deliverable.length === this.items.length ? true : false
      this.allFilterableModel = this.filterable.length === this.items.length ? true : false
    },
    filterable(val) {
      this.filterableModel.forEach((enabled, index) => {
        const itemAtIndex = this.items.find(item => item.id === index + 1)
        if (this.filterable.find(filterableColumn => filterableColumn.name === itemAtIndex.name) !== undefined) {
          Vue.set(this.filterableModel, index, true)
        } else {
          Vue.set(this.filterableModel, index, false)
        }
      })
      this.allDeliverableModel = this.deliverable.length === this.items.length ? true : false
      this.allFilterableModel = this.filterable.length === this.items.length ? true : false
    }
  },
  mounted() {
    if (this.dataset) {
      this.computeItems()
    }
  },
  methods: {
    allDeliverableUpdated(value) {
      if (value) {
        const allItems = this.sortById(this.items.map(item => this.getColumnByName(item.name)))
        this.$emit('stepPayloadChanged', {
          deliverable: allItems,
          filterable: allItems
        })
      } else {
        this.$emit('stepPayloadChanged', {
          deliverable: [],
          filterable: this.filterable
        })
      }
    },
    allFilterableUpdated(value) {
      if (value) {
        const allItems = this.sortById(this.items.map(item => this.getColumnByName(item.name)))
        this.$emit('stepPayloadChanged', {
          deliverable: this.deliverable,
          filterable: allItems
        })
      } else {
        this.$emit('stepPayloadChanged', {
          deliverable: this.deliverable,
          filterable: []
        })
      }
    },
    isDeliverable(item) {
      return this.deliverable.find(deliverableItem => deliverableItem.name === item.name) !== false
    },
    isFilterable(item) {
      return this.deliverable.find(deliverableItem => deliverableItem.name === item.name) !== false
    },
    deliverableUpdated(item, value) {
      if (value) {
        const updatedDeliverable = this.sortById([...this.deliverable, this.getColumnByName(item.name)])
        this.$emit('stepPayloadChanged', {
          deliverable: updatedDeliverable,
          filterable: this.filterable
        })
        this.filterableUpdated(item, value, updatedDeliverable)
      } else {
        const updatedDeliverable = this.deliverable.filter(deliverableColumn => deliverableColumn.name !== item.name)
        this.$emit('stepPayloadChanged', {
          deliverable: updatedDeliverable,
          filterable: this.filterable
        })
      }
    },
    filterableUpdated(item, value, updatedDeliverable) {
      if (value) {
        this.$emit('stepPayloadChanged', {
          deliverable: updatedDeliverable ? updatedDeliverable : this.deliverable,
          filterable: !this.filterable.find(filterableItem => filterableItem.name === item.name) ? this.sortById([...this.filterable, this.getColumnByName(item.name)]) : this.filterable
        })
      } else {
        this.$emit('stepPayloadChanged', {
          deliverable: updatedDeliverable ? updatedDeliverable : this.deliverable,
          filterable: this.filterable.filter(filterableColumn => filterableColumn.name !== item.name)
        })
      }
    },
    getColumnByName(columnName) {
      return this.items.find(item => item.name === columnName)
    },
    computeItems() {
      const sellableItems = Dataset.getDatasetColumns(this.dataset).filter(column => {
        return this.dataset.schema.unsellable ? !this.dataset.schema.unsellable.includes(column.name) : true
      })
      this.items = sellableItems.map((column, index) => {
        return {
          ...column,
          id: index + 1
        }
      })
      this.$emit('stepPayloadChanged', {
        deliverable: this.items,
        filterable: this.items
      })
      this.deliverableModel = this.items.map(item => true)
      this.filterableModel = this.items.map(item => true)
      this.loading = false
    },
    sortById(items) {
      return items.sort((a, b) => {
        if ( a.id < b.id ){
          return -1
        }
        if ( a.id > b.id ){
          return 1
        }
        return 0
      })
    }
  }
}
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.columns-step
  .columns
    .invalid
      margin-top: 24px
      display: flex
    ::v-deep .v-data-table__wrapper
      overflow: hidden
    ::v-deep .nio-slat-title, ::v-deep .nio-slat-subtitle
      width: 600px
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis
    ::v-deep .action-cell, ::v-deep .select-all
      display: flex
      justify-content: flex-end
      align-items: center
      width: unset !important
      height: 76px
      .nio-switch
        margin-bottom: 0px
        margin-top: 0px
        width: 50px
        .v-input__slot
          margin-bottom: 0px
        .v-messages
          display: none
      & > * + .nio-switch
        margin-left: 8px
      .nio-p-small
        margin-left: 4px
    ::v-deep .select-all
      .nio-p-small
        visibility: hidden
  
</style>

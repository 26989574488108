<template lang="pug">
  .product-wrapper
    .card
      .grid-card
        .header
          .graphic
            v-img.image(
              :src="preview.imageUrl ? preview.imageUrl : 'https://cdn.narrative.io/data-studio/images/narrative-placeholder-primary.svg'" 
              :elevation="5"
            )
          .category
            .platform-body.bold.text-primary-dark {{ preview.category }}
        .content
          h4.nio-h4.name-wrapper {{ preview.name }}
          .stats
            .ct
              NioPill(price) {{ formatCurrency(cost) }}
              span.nio-p-small.primary-dark(v-if="cost > 0") Per 1k
</template>

<script>

import { formatCurrency } from '@/modules/helpers'

export default {
  props: {
    preview: {
      type: Object,
      required: true
    },
    cost: {
      type: Number,
      required: true
    }
  },
  methods: {
    formatCurrency(val) {
      return val === 0 ? 'FREE' : formatCurrency(val)
    },
    capitalize(s) {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    }
  }
}
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

=nio-card($padding: 1.875rem, $border-radius: 0.3125rem)
  &:not(.v-sheet--tile):not(.v-card--shaped)
    border-radius: $border-radius
  box-shadow: $box-shadow-card
  background-color: #FFFFFF
  padding: $padding
  &.elevated
    box-shadow: $box-shadow-card-elevated

=nio-item-card($height: 16.25rem)
  &:not(.informational)
    +nio-card($padding: 0rem, $border-radius: 0.75rem)
    position: relative
    background-color: $c-white
    box-shadow: $box-shadow-card
    border-radius: 0.75rem
    transition: all .2s ease-out
    overflow: hidden
    margin-bottom: 1.25rem
    .card

  &.informational			
    padding: 0rem
    margin-bottom: 1.25rem
    .hover-controls
      display: none
    .card
      height: 100%
      border-radius: 0.75rem
      display: flex
      flex-direction: column
      justify-content: space-between
      align-items: center
      text-align: center
      background-color: rgba(0,0,0,0)
      border: 0.125rem solid $c-primary-lighter
      .how-it-works
        border-radius: 2.5rem
        &:hover
          text-decoration: none

.product-wrapper
  +nio-item-card
  .card
    padding: 1.5rem
    .cta
      display: flex
      flex-direction: column
      align-items:  center
      justify-content: center
      height: 100%
      padding: 1rem
    .grid-card
      .header
        display: flex
        justify-content: space-between
        height: 6rem
        .category
          text-align: right
          font-size: 0.75rem
          letter-spacing: 0.0625rem
          text-transform: uppercase
        .graphic
          display: flex
          justify-content: center
          align-items: center
          width: 6rem
          height: 6rem
          border-radius: 0.75rem
          box-shadow: 0rem 0.1875rem 0.3125rem rgba(0, 0, 0, 0.15)
          margin-bottom: 1.25rem
          background-image: white
          border-radius: 0.75rem
          overflow: hidden

      .content     
        cursor: pointer
        display: flex
        flex-direction: column
        justify-content: flex-end
        text-align: left
        color: $c-primary-darker
        .name-wrapper
          height: 6.125rem
          display: flex
          flex-direction: column
          justify-content: flex-end
          
        .stats  
          display: flex
          justify-content: space-between
          align-items: center
          margin-top: 1rem

          .btn-hover
            opacity: 0
            z-index: 100
            position: absolute
            bottom: 1.875rem
            right: 1rem

          .nio-pill.price
            width: auto
            margin-right: 1rem

</style>
<template lang="pug">
  .description-step
    .header.step-header
      h2.nio-h2.text-primary-darker Description
    .step-loading(v-if="loading")
      v-progress-circular.progress(size="80" indeterminate color="#1438F5")
    .description-container
      .name.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Name
          .description.nio-p.text-primary-dark The name of the data stream as displayed to customers.
        .filter-value
          NioTextField(
            v-model="model.name"
            :rules="[rules.required]"
            label="Data Stream Name"
            @update="populateSlug(model.name)"
            validate-on-blur
          )
      .slug.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Slug
            NioTooltip(
              :data="tooltips.slug"
            )
          .description.nio-p.text-primary-dark The permalink for the data stream product page, which can impact SEO.
        .filter-value
          .example
            span.nio-p.text-primary-dark https://www.example.com/
            span.nio-p.text-primary-darker {{ model.slug }}
          NioTextField(
            v-model="model.slug"
            :rules="[rules.required, rules.maxLength]"
            label="Data Stream Slug"
            validate-on-blur
          )
          .checking-slug-message
            .nio-p.text-primary-dark(v-if="checkingCurrentSlug") Checking availability...
            .nio-p.text-success(v-else-if="model.slug && model.slug.length > 0 && !checkingCurrentSlug && slugValid") Slug is available!
            .nio-p.text-error(v-else-if="model.slug && model.slug.length > 0 && !checkingCurrentSlug && !slugValid") Sorry, this slug is not available
      .description.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Description
          .description.nio-p.text-primary-dark Provide information about this data stream to customers. If your Data Shop is public, we'll add a JSON-LD product schema to help buyers discover your data stream on search engines.
        .filter-value
          NioTextarea(
            ref="description"
            v-model="model.description"
            :rules="[rules.required]"
            :rows="3"
            auto-grow
            placeholder="Data Stream Description"
            validate-on-blur
          )
          NioButton.flex.align-center(
            v-if="isAdminUser"
            :loading="suggestionLoading"
            :disabled="!enableSuggestion"
            normal-secondary
            @click="suggestDescription"
          )
            NioTooltip.mr-2(
              open-on-hover
              message="Suggestions are powered by machine-learning, courtesy of OpenAI GPT-3 model. Data Stream title and column names are submitted as input, and the model does its best to provide you with an accurate and readable description of your product to get you started. Please read all suggestions carefully and edit for clarity and accuracy"
            )
            span suggest Description     
      .image.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Image
          .description.nio-p.text-primary-dark Provide a product image to showcase the data stream.
        .filter-value
          NioTextField(
            v-model="model.image"
            label="Data Stream Image URL"
            validate-on-blur
          )
      .category.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Category
          .description.nio-p.text-primary-dark Provide an optional category for the data stream.
        .filter-value
          NioTextField(
            v-model="model.category"
            label="Data Stream Category"
          )
      .tags.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Tags
          .description.nio-p.text-primary-dark Add tags for discoverability on your sales channels.
        .filter-value
          NioTagsField(
            v-model="model.tags"
            label="Add Tags"
          )
</template>

<script>
import TooltipsData from '@/modules/tooltipsData'
import axios from 'axios'

var slugify = require('slugify')

export default {
  props: {
    "completedSummary": { type: Object, required: false },
    "columns": { type: Object, required: false }
  },
  data: () => ({
    tooltips: TooltipsData.pages.new.steps.description,
    loading: false,
    model: {
      name: '',
      image: null,
      description: null,
      slug: '',
      tags: [],
      category: null
    },
    slugValid: false,
    previousSlug: null,
    checkingSlugs: [],
    suggestionLoading: false,
    rules: {
      required: value => !!value || 'Required',
      maxLength: value => value && value.length < 256 || 'Maximum of 255 characters allowed'
    }
  }),
  computed: {
    enableSuggestion() {
      return this.model.name && this.columns && this.columns.deliverable && this.columns.deliverable.length > 0
    },
    isAdminUser() {
      return this.nioUser.role && this.nioUser.role === 99
    },
    checkingCurrentSlug() {
      return this.checkingSlugs.includes(this.model.slug)
    }
  },
  watch: {
    model: {
      deep: true,
      handler() {
        if (this.model.slug !== this.previousSlug) {
          this.slugValid = false
          this.previousSlug = this.model.slug
          this.checkSlug(this.model.slug)
        }
        this.$emit('stepPayloadChanged', { model : this.model, slugValid: this.slugValid })
      }
    }
  },
  methods: {
    populateSlug(name) {
      this.model.slug = slugify(name,{
        remove: /[*+~.()'"!:@]/g
      })
    },
    async checkSlug(slug) {
      this.checkingSlugs.push(slug)
      try {
        await this.$nioOpenApi.get(`/data-stream/company/${this.nioUser.contextCompany}/slug/${encodeURIComponent(this.model.slug)}`)
      } catch (error) {
        if (error.message === 'Request failed with status code 404' && this.model.slug === slug) {
          this.slugValid = true
          this.$emit('stepPayloadChanged', { model : this.model, slugValid: this.slugValid })
        }
      }
      const slugIndex = this.checkingSlugs.indexOf(slug)
      if (slugIndex > -1) {
        this.checkingSlugs.splice(slugIndex, 1)
      }
    },
    async suggestDescription() {
      this.suggestionLoading = true
      const columnNames = this.columns.deliverable.map(column => `'${column.name}'`).join(', ') 
      const prompt = `Write a long form product description that will be used to help sell a data product called '${this.model.name}'. The columns in the dataset are ${columnNames}. Please return results HTML formatted. No not include a header tag.`
      const resp = await this.makeRequest(prompt)
      if (resp) {
        this.model.description = resp.trim()
      }
      this.$nextTick(() => {
        const theElement = this.$refs.description.$el       
        const input = theElement.querySelector('input:not([type=hidden]),textarea:not([type=hidden])')
        if (input) {
          setTimeout(() => {
            input.focus()
          }, 0)
        }
      })
      this.suggestionLoading = false
    },
    async makeRequest(prompt) {
      const body = {
        "prompt": prompt,
        "max_tokens": 192,
        user: `${this.nioUser.id}`
      }
      try {
        parent.postMessage({
          name: 'hubspotAnalyticsEvent',
          payload: {
            eventName: 'sellerStudioOpenAIDescriptionSuggested',
            params: {}
          }
        }, "*")
      } catch {}
      const resp = await this.$nioOpenApi.post('v1/proxy/open-ai/v1/engines/text-davinci-002/completions', body)
      if (resp.status === 200) {
        return Promise.resolve(resp.data.choices[0].text)
      }
      else {
        return Promise.reject()
      }
    }
  }
}

</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"
@import "@narrative.io/tackle-box/src/styles/mixins/filter/_filter-header"

.description-step
   .description-container
      border-radius: 0.75rem
      border: 0.0625rem solid $c-primary-lighter
      overflow: hidden
      .nio-textarea
        width: 100%
        ::v-deep .v-input__slot
          border: 0.0625rem solid $c-primary-light
        ::v-deep textarea::placeholder
          color: $c-primary-dark
      .filter
        +nio-filter-header
        .title-description
          padding-right: 1.5rem
          .description
            max-width: 20rem
        .filter-value
          .nio-text-field, .nio-tags-field
            width: 100%
        &.slug .filter-value
          margin-top: -0.4375rem
          .checking-slug-message
            position: absolute
            right: 2rem
            bottom: 1.0625rem
            .nio-p
              font-size: 0.75rem
      & > .filter + .filter
        border-top: 0.0625rem solid $c-primary-lighter
</style>

<template lang="pug">
  .review-step
    .header.step-header
      h2.nio-h2.text-primary-darker Review and Activate
    .step-loading(v-if="loading")
      v-progress-circular.progress(
        size="80" 
        color="#1438F5"
        indeterminate 
      )
    .review
      .preview    
        .tile(v-if="getActiveOffer('data_shop')")
          .nio-h4.text-primary-darker Your Data Shop Offer
          ProductCard(
            :preview="preview"
            :cost="getActiveOffer('data_shop').value"
          )
          .cost-forecast
            .nio-h5.text-primary-darker Cost forecast
              NioTooltip(
                open-on-hover
                message="The estimated total cost to purchase all data in this Data Stream"
              )
            .nio-p.text-primary-dark {{ !forecastParamsStale && forecastedCost('data_shop') ? formatPrice(forecastedCost('data_shop')) : 'N/A'}}
        .tile(v-if="getActiveOffer('data_streams_market')")
          .nio-h4.text-primary-darker Data Stream Marketplace Offer
          ProductCard(
            :preview="preview"
            :cost="getActiveOffer('data_streams_market').value"
          )
          .cost-forecast
            .nio-h5.text-primary-darker Cost forecast
              NioTooltip(
                open-on-hover
                message="The estimated total cost to purchase all data in this Data Stream"
              )
            .nio-p.text-primary-dark {{ !forecastParamsStale && forecastedCost('data_streams_market') ? formatPrice(forecastedCost('data_streams_market')) : 'N/A'}}
      .connectors(v-if="getActiveOffer('Narrative TTD 3p Custom Connector')")
        .nio-h4.text-primary-darker Destinations
        .connector.ttd-3p(v-if="getActiveOffer('Narrative TTD 3p Custom Connector')")
          .header
            NioImageTile(
              :src="`data:${getActiveOffer('Narrative TTD 3p Custom Connector').icon.imageType};base64, ${getActiveOffer('Narrative TTD 3p Custom Connector').icon.image}`"
              :alt="getActiveOffer('Narrative TTD 3p Custom Connector').icon.alt"
            )
            .header-content
              h4.nio-h4.text-primary-darker.name {{ getActiveOffer('Narrative TTD 3p Custom Connector').displayName }}
              p.nio-p.text-primary-dark {{ preview.name }}
              .nio-h5.text-primary-darker {{ ttdConnectorSummary(getActiveOffer('Narrative TTD 3p Custom Connector')) }}
            

</template>

<script>

import ProductCard from './ProductCard'
import { formatCurrency } from '@/modules/helpers'
import { ttdConnectorSummary } from '../../destinations'

export default {
  components: { ProductCard },
  props: {
    "preview": { type: Object, required: true },
    "forecast": { type: Object, required: false },
    "forecastParamsStale": { type: Boolean, required: false, default: false }
  },
  data: () => ({
    loading: true
  }),
  mounted() {
    this.loading = false
  },
  methods: {
    formatPrice(price) {
      return formatCurrency(price)
    },
    getActiveOffer(offerName) {
      return this.preview.offers ? this.preview.offers.find(offer => offer.name === offerName && offer.active) : null
    },
    formatPrice(price) {
      return formatCurrency(price)
    },
    forecastedCost(offerName) {
      const rowCount = this.forecast?.result?.rows
      if (rowCount) {
        const costPerRecord = this.getActiveOffer(offerName).value / 1000
        return rowCount * costPerRecord
      }
      return undefined
    },
    ttdConnectorSummary(offer) {
      return ttdConnectorSummary(offer)
    }
  }
}
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.review-step
  .review
    display: flex
    flex-direction: column
    padding: 2rem
    background: $c-canvas
    border: 0.0625rem solid $c-primary-lighter
    border-radius: 0.75rem
    margin-bottom: 1.5rem
    .preview
      display: flex
      justify-content: center
      align-items: center
      .tile
        display: flex
        flex-direction: column
        align-items: center
        .nio-h4
          margin-bottom: 1.5rem
        .cost-forecast
          display: flex
          flex-direction: column
          align-items: center
          width: 23.75rem
          margin-top: 16px
          * 
            text-align: center
      .product-wrapper
        width: 23.75rem
        margin-bottom: 0rem
      & > .tile + .tile
        margin-left: 1.5rem
    .connectors
      display: flex
      flex-direction: column
      align-items: center
      margin-top: 3rem
      .connector
        width: 100%
        display: flex
        flex-direction: column
        background-color: white
        border-radius: 0.75rem
        padding: 1.5rem
        margin-top: 1.5rem
        width: 23.75rem
        .header
          display: flex
          align-items: center
          align-items: center
          width: 100%
          .nio-image-tile
            width: 3rem !important
            height: 3rem !important
            flex-grow: 0
            flex-shrink: 0
          .header-content
            display: flex
            flex-direction: column
            justify-content: center
            align-items: flex-start
            margin-left: 1.5rem
            p
              margin-bottom: 0rem !important
        .offer-content
          margin-top: 1.5rem
          .stacked-details
            display: flex
            flex-direction: column
            align-items: flex-start
            .stacked-detail
              display: flex
              justify-content: space-between
              .label
                display: flex
                justify-content: flex-start
                width: 9.375rem
              .value
                display: flex
                justify-content: flex-start
            & > .stacked-detail + .stacked-detail
              margin-top: 0.5rem

          
</style>

import { v4 as uuidv4 } from 'uuid'

let makeDataStream = (payload, dataset, deduplication, buyers) => {
  const dataStream = {
    name: payload.description.model.name,
    slug: payload.description.model.slug,
    content: {
      description: payload.description.model.description,
      icon: payload.description.model.image && payload.description.model.image !== '' ? payload.description.model.image : null,
      sections: []
    },
    tags: payload.description.model.tags && payload.description.model.tags.length > 0 ? payload.description.model.tags : null,
    category: payload.description.model.category && payload.description.model.category.length > 0 ? payload.description.model.category : null,
    offers: makeOffers(payload.offers),
    data_rules: makeDataRules(payload,deduplication),
    auth_rules: makeAuthRules(buyers)
  }
  return dataStream
}

function makeAuthRules(buyers) {
  return buyers.constraintType !== 'none' ? {
    company_constraint: {
      type: buyers.constraintType,
      company_ids: buyers.companyIds
    }
  } : null
}

function makeOffers(offers) {
  const result = []
  const baseOffers = offers.filter(offer => (offer.name === 'data_streams_market' || offer.name === 'data_shop') && offer.active)
  baseOffers.forEach(offer => {
    result.push({
      id : uuidv4(),
      channel : offer.name,
      licensing : {
        period : `P${offer.license.period}D`,
        license : {
          name : offer.license.licenses[0],
          text : null
        }
      },
      pricing : {
        micro_cents_usd : parseInt(offer.value / 1000 * 1000000 * 100)
      },
      shops : null
    })
  })
  return result.length > 0 ? result : null
}

function makeFilter(filters, columnName) {
  const filter = filters.find(filter => filter.title === columnName)
  if (!filter) {
    return null
  } else if (filter.value === 'default') {
    return null
  } else if (filter.value === 'ifPresent') {
    return "include_only_if_not_null_filter"
  } else {
    switch (filter.type) {
      case 'stringLimited':
        return filter.customOption.value.items.length > 0 ? {
          type : "include",
          list : filter.customOption.value.items
        } : null
      case 'stringMany':
        const items = filter.customOption.value.manualEntry.split("\n").map(val => val.trim())
        return filter.customOption.value.manualEntry && items.length > 0 ? {
          type : filter.customOption.value.listType,
          list : items
        } : null
      case 'number':
        return filter.customOption.value[0] || filter.customOption.value[1] ? {
          min : filter.customOption.value[0] ? { // QUESTION: can min or max be null?
            type : "inclusive",
            value : String(filter.customOption.value[0])
          } : null,
          max : filter.customOption.value[1] ? {
            type : "inclusive",
            value : String(filter.customOption.value[1])
          } : null
        } : null
      case 'simpleTimestamp':
        return filter.customOption.value.start.enabled || filter.customOption.value.end.enabled || filter.customOption.value.recency.enabled ? {
          recency: filter.customOption.value.recency.enabled ? `P${filter.customOption.value.recency.value}${filter.customOption.value.recency.period}` : null,
          from : filter.customOption.value.start.enabled ? { // QUESTION: can min or max be null?
            type : "inclusive",
            value : filter.customOption.value.start.timestamp
          } : null,
          to : filter.customOption.value.end.enabled ? {
            type : "inclusive",
            value : filter.customOption.value.end.timestamp
          } : null
        }	: null
      case 'boolean':
        return filter.customOption.value === true || filter.customOption.value === false ? {
          type: "inclusive",
          list: String(filter.customOption.value)
        } : null
      default:
        return null
    }
  }
}

function makeDataRules(payload, deduplication) { 
  const deduplicationObj = deduplication[0].value === 'custom' ? {
    period: `P${deduplication[0].customOption.value[0]}${deduplication[0].customOption.value[1]}`,
    column_references: [{
      dataset_id: payload.dataset.id,
      column_names: deduplication[0].customOption.supportingOption.value
    }]
  } : null
  const dataRulesObj = {
    column_sets: [{
      dataset_id: payload.dataset.id,
      fields: Object.keys(payload.dataset.schema.properties).map(key => {
        return {
          field: key,
          filter: payload.filters ? makeFilter(payload.filters, key) : null,
          exported: payload.columns.deliverable.find(column => column.name === key) !== undefined
        }
      })
      // https://app.shortcut.com/narrativeio/story/22442
      // Un-filtered, un-exported fields are no-ops and should be included in data_rules payload.
      .filter(field => field.exported || field.filter)
    }],
    deduplication: deduplicationObj
  }
  return dataRulesObj
}

export {
  makeDataStream,
  makeDataRules
}
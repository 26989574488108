<template lang="pug">
  .buyers-step
    .header.step-header
      h2.nio-h2.text-primary-darker Pick buyers
      p.nio-p.text-primary-dark Select buyers you want to sell to or exclude
    .step-loading(v-if="loading")
      v-progress-circular.progress(
        size="80" 
        color="#1438F5"
        indeterminate 
      )
    .buyers-filter(v-if="!loading")
      NioFilter(
        :filter="buyersFilter"
        solo
      )
</template>
  
<script>
  import { NioOpenApiModule } from '@narrative.io/tackle-box'
  import { createNewBuyersFilter } from './buyers-filters'
  import { setHeaders } from '@/utils/ttdServiceLayer'
  
  export default {
    props: {
      "completedSummary": { type: Object, required: false }
    },
    data: () => ({
      buyers: null,
      buyersFilter: null,
      loading: true
    }),
    computed: {
    },
    watch: {
      buyersFilter: {
        deep: true,
        handler() {
          this.buyersUpdated()
        }
      },
    },
    mounted() {
      NioOpenApiModule.initCallback(this.openApiInit)
    },
    methods: {
      async openApiInit(token) {
        setHeaders(token)
        this.getBuyers()
      },
      buyersUpdated() {
        this.buyers = this.buyersFilter.value !== 'default' && this.buyersFilter.customOption.value.items.length > 0 ?
          {
            constraintType: this.buyersFilter.customOption.value.listType === 'include' ? "inclusion" : "exclusion",
            companyIds: this.buyersFilter.customOption.value.items
          } : {
            constraintType: "none"
          }
        this.$emit('stepPayloadChanged', this.buyers)
      },
      getBuyers() {
        return this.$nioOpenApi.get(`/company-info`).then(res => {
          const buyersResp = res.data.records.map(company => {
            return {
              value: company.id,
              label: `${company.name} (${company.id})`
            }
          }).sort((a, b) => {
            if ( a.label < b.label ){
              return -1
            }
            if ( a.label > b.label ){
              return 1
            }
            return 0
          })
  
          this.buyersFilter = createNewBuyersFilter(this.buyers, buyersResp)
          this.loading = false
        })
      }
    }
  }
  </script>
  
  <style lang="sass" scoped>
  
  @import "@narrative.io/tackle-box/src/styles/global/_colors"
  @import "@narrative.io/tackle-box/src/styles/mixins/filter/_filter-header"
  @import "@narrative.io/tackle-box/src/styles/mixins/slat/_slat-group"
 
  .buyers-step
  .step-header
    margin-bottom: 0rem
  ::v-deep .nio-filter-property
    background-color: $c-white
    border-top: none !important
    .heading-description
      display: none
    .custom-option  
      border: 0.0625rem solid $c-primary-lighter
      border-radius: 0.75rem
      .option-content
        padding: 2rem 1.5rem !important
        background-color: $c-canvas !important
  </style>
  
import * as Dataset from './dataset'
import moment from 'moment'

const defaultOption = {
  label: `Optional Attribute`,
  value: 'default',
  tooltip: 'Include all rows of data regardless of the value of this attribute.'
}

const includeIfPresentOption = {
  label: "Required Attribute",
  value: 'ifPresent',
  tooltip: 'Include only rows of data in which the value of this attribute is not null.'
}

const customOption = {
  label: 'Custom Filtering',
  value: 'custom',
  tooltip: 'Include only rows of data in which this attribute’s value(s) fits my indicated criteria.'
}


function initFilters(columns) {
  const filters = []
  columns.forEach(column => {
    switch (column.type) {
      case 'string':
        if (column.enum) {
          filters.push(makeStringLimitedFilter(column))
        } else {
          filters.push(makeStringSimpleFilter(column))
        } 
        break
      case 'long':
      case 'double':
        filters.push(makeUnconstrainedNumericFilter(column))	
        break
      case 'boolean':
        filters.push(makeBooleanFilter(column))
        break
      case 'timestamptz':
        filters.push(makeTimestampFilter(column))
        break
      default:
        break
    }
  })
  return filters
}

function makeStringLimitedFilter(column) {
  return {
    name: column.column_id,
    type: "stringLimited",
    title: column.name,
    description: column.description,
    value: "default",
    options: [
      defaultOption,
      includeIfPresentOption,
      customOption
    ],
    customOption: {
      config: {
        searchable: true,
        multiple: true,
        items: column.enum.sort().map(item => { 
          return  {
            value: item,
            label: item
          }
        })
      },
      value: {
        items: []
      }	
    }
  }
}

function makeStringSimpleFilter(column) {
  return {
    name: column.column_id,
    type: "stringMany",
    title: column.name,
    description: column.description,
    value: "default",
    options: [
      defaultOption,
      includeIfPresentOption,
      customOption
    ],
    customOption: {
      config: {
        manualEntryOnly: true
      },
      value: {
        listType: 'include',
        manualEntry: ''
      }
    }
  }
}

function makeUnconstrainedNumericFilter(column) {
  return { 
    name: column.column_id,
    type: "number",
    title: column.name,
    description: column.description,
    value: 'default',
    options: [
      defaultOption,
      includeIfPresentOption,
      customOption
    ],
    customOption: {
      config: {
        unconstrained: true
      },
      value: [null, null]
    }
  }
}

function makeBooleanFilter(column) {
  return { 
    name: column.column_id,
    type: "boolean",
    title: column.name,
    description: column.description,
    value: 'default',
    options: [
      defaultOption,
      includeIfPresentOption,
      customOption
    ],
    customOption: {
      value: true
    }
  }
}

function makeTimestampFilter(column) {
  return {
    name: column.column_id,
    type: "simpleTimestamp",
    title: column.name,
    description: column.description,
    value: 'default',
    options: [
      defaultOption,
      includeIfPresentOption,
      customOption
    ],
    customOption: {
      config: {
        periodOptions: [
          {
            label: 'Day(s)',
            value: 'D'
          },
          {
            label: 'Week(s)',
            value: 'W'
          },
          {
            label: 'Month(s)',
            value: 'M'
          }
        ]  
      },
      value: {
        recency: {
          enabled: false,
          period: 'D',
          value: 30
        },
        start: {
          enabled: true,
          inclusive: false,
          timestamp: moment(new Date()).format('YYYY-MM-DD'),
        },
        end: {
          enabled: true,
          inclusive: false,
          timestamp: moment(new Date()).add("days", 1).format('YYYY-MM-DD')
        } 
      }
    },
    valid: true
  }
}   

function makeDeduplicationFilter(filterObj, deliverableFilters) {
  const filterCopy = filterObj
  const options = []
  deliverableFilters.forEach(filter => {
    options.push(filter.name)
  })
  filterCopy.customOption.supportingOption.config.items = options
  filterCopy.customOption.supportingOption.value = options
  return [filterCopy]
}




let deduplicationFilterValid = (filter) => {
  const result = {
    formatValid: false,
    periodValid: false,
    pathsValid: false
  }

  if (filter.customOption.value[0] !== '' && filter.customOption.value[0].indexOf('.') > -1) {
    result.formatValid = false
  } else {
    result.formatValid = true
  }
  if (parseInt(filter.customOption.value) < 1 || filter.customOption.value[0] === '' || parseInt(filter.customOption.value) > 60) {
    result.periodValid = false
  } else {
    result.periodValid = true
  }
  if (filter.customOption.supportingOption.value?.length > 0) {
    result.pathsValid = true
  } else {
    result.pathsValid = false
  }

  return result
}

export default {
  initFilters,
  makeDeduplicationFilter,
  deduplicationFilterValid
}
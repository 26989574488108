<template lang="pug">
  .filters-step
    .header.step-header
      h2.nio-h2.text-primary-darker Apply filters
      p.nio-p.text-primary-dark Filters limit the amount of data included in the data stream. All columns are unfiltered by default.
    .step-loading(v-if="loading")
      v-progress-circular.progress(
        size="80" 
        color="#1438F5"
        indeterminate 
      )
    NioFilterGroup(
      v-if="!loading && filters"
      :filters="filters"
    )
    p.nio-p.text-primary-dark.text-center(v-if="!hasFilterable") No Attribute Filters Selected
    NioFilterGroup(
      v-if="deduplication && hasFilterable"
      :filters="deduplication"
    )
    .invalid-error(v-if="deduplication && deduplication[0].value === 'custom' && hasFilterable")
      .nio-p.text-error(v-if="!deduplicationPeriodValid") Deduplication frequency must be a positive number <= 60
      .nio-p.text-error(v-else-if="!deduplicationFormatValid") Deduplication frequency must be a whole number
      .nio-p.text-error(v-else-if="!deduplicationPathsValid") Deduplication must specify at least one data point
</template>

<script>

import filtersModule from '../../../../modules/filtersModule'
import { formatFloat } from '../../../../modules/helpers'

export default { 
  props: {
    "filters": { type: Array, required: false, default: null},
    "deduplication": { type: Array, required: false, default: null},
    "deduplicationPeriodValid": { type: Boolean, required: false, default: false },
    "deduplicationFormatValid": { type: Boolean, required: false, default: false },
    "deduplicationPathsValid": { type: Boolean, required: false, default: false },
    "hasFilterable": { type: Boolean, required: false, default: true }
  },
  data: () => ({
    loading: true,
    generalErrorMsg: null
  }),
  mounted() {
    if (this.filters && this.filters.length) {
      this.loading = false
    }
  },
  watch: {
    filters: {
      deep: true,
      handler(val) {
        this.$emit('stepPayloadChanged')
      }
    },
    deduplication: {
      deep: true,
      handler(val) {
        this.$emit('stepPayloadChanged')
      }
    }
  }
}
</script>

<style lang="sass">

@import "@narrative.io/tackle-box/src/styles/global/_colors"
@import "@narrative.io/tackle-box/src/styles/mixins/filter/_filter-header"

.filters-step
  .data-package-price
    border-radius: 0.75rem
    border: 0.0625rem solid $c-primary-lighter
    overflow: hidden
    margin: 0rem 0rem 1.5rem 0rem
    .price-cap
      +nio-filter-header
      .filter-value
        display: flex
        flex-direction: column
        .nio-slider
          width: 100%
        .description
          margin-top: 1rem
  .nio-filter-group
    margin-bottom: 1.5rem
  .deduplication
    border-radius: 0.75rem
    border: 0.0625rem solid $c-primary-lighter
    overflow: hidden
    margin: 0rem 0rem 1.5rem 0rem
  .invalid-error
    position: absolute
    bottom: 3.125rem   
  .filter-title
    width: 400px
    white-space: initial
    word-wrap: break-word       
</style>

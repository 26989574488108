<template lang="pug">
  .dataset-step
    .header.step-header
      h2.nio-h2.text-primary-darker Choose a Dataset
    .step-loading(v-if="loading")
      v-progress-circular.progress(
        size="80" 
        color="#1438F5"
        indeterminate 
      )
    .columns
      NioSlatTable(
        v-if="tableColumns && datasets"
        :items="datasets"
        :columns="tableColumns"
        :initial-items-per-page="10"
        :sort-options="sortOptions"
        :searchable-props="['display_name', 'id']"
        action="expand"
        search-sort-header
        single-select
        pagination
        @selectionChanged="selectionChanged($event)"
      )
        template(v-slot:item-expanded="slotProps")
          .nio-p.text-primary-dark <strong>Description:</strong> {{ slotProps.item.description ? slotProps.item.description : 'none provided' }}
          .tags
            NioPill(
              v-for="tag of slotProps.item.tags"
              tag
            ) {{ tag }}
</template>

<script>

import { NioOpenApiModule } from '@narrative.io/tackle-box'
import sortOptions from './sortOptions'
import * as Dataset from '@/modules/dataset'
import moment from 'moment'

export default {
  props: {
    "completedSummary": { type: Object, required: false },
    "datasets": { type: Array, required: true } 
  },
  data: () => ({
    loading: true,
    sortOptions: sortOptions,
    items: null,
    tableColumns: null  
  }),
  mounted() {
    this.loading = false
    this.tableColumns = [
      {
        name: "slat",
        props: {
          title: 'display_name',
          label: 'Name',
          subtitle: this.computeId
        }
      },
      {
        name: "columns",
        label: "Columns",
        computed: this.computeNumColumns
      },
      {
        name: "created",
        label: "Created",
        computed: this.computeCreated
      }
    ]
  },
  methods: {
    selectionChanged(selection) {
      this.$emit('stepPayloadChanged', selection)
    },
    computeId(item) {
      return `#${item.id}`
    },
    computeNumColumns(item) {
      return Dataset.getDatasetColumns(item).length
    },
    computeCreated(item) {
      return moment(item.created_at).format('MMM D, YYYY')
    }
  }
}
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.dataset-step
  ::v-deep .expanded-row
    padding: 1.5rem 1.5rem 2rem 1.5rem
    background-color: $c-canvas
    .tags
      margin-top: 12px
      margin-left: -6px
    .tags > .nio-pill
      margin-left: 6px
  ::v-deep .nio-slat-title
    max-width: 500px
    white-space: pre-wrap !important
</style>

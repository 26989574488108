<template lang="pug">
  .new-page
    NioForecastWidget(
      :class="{'visible': currentStep !== 'dataset' && currentStep !== 'columns' && !loading}"
      :forecast-params="forecastParams"
      :open-api-base-url="openApiBaseUrl"
      :open-api-token="openApiToken"
      :disable-group-by="true"
      :forecast-params-stale="forecastStale"
      :hide-data-cost="true"
      @forecastStarted="forecastStarted"
      @forecastComplete="forecastComplete($event)"
    )
    .app-loading(v-if="loading")
      v-progress-circular.progress(
        size="80" 
        color="#1438F5"
        indeterminate 
      )
    .header.app-header
      h1.nio-h1.text-primary-darker New Data Stream
    .no-datasets(
      v-if="datasets && datasets.length === 0"
    )
      NioIconFramer(
        icon-name="display-list"
      )
      h3.nio-h3.text-primary-darker You have no active datasets
      NioButton(
        normal-secondary
        @click="learnDatasets"
      ) Manage Datasets
    NioStepper(
      v-else-if="steps"
      :ordered-steps="steps"
      :current-step="currentStep"
      :completed-steps="completedSteps"
      final-step-label="Activate Data Stream"
      @nextStep="nextStep"
      @previousStep="previousStep"
      @submit="createDataStream"
      @stepSelected="stepSelected($event)"
    )
      NioStep(
        v-if="steps.includes('dataset')"
        :valid="stepPayloads.dataset !== null"
        :summary="makeStepSummary('dataset')"
        stepName="dataset"
        simpleSummary
      )
        template(v-slot:content)
          NioDivider(horizontal-solo)
          DatasetStep(
            v-if="datasets"
            :datasets="datasets"
            :current-step="currentStep"
            :completed-summary="makeStepSummary('dataset')"
            @stepPayloadChanged="updatePayload('dataset', $event)"
            @setStepIncomplete="setStepIncomplete('dataset')"
          )
      NioStep(
        stepName="columns"
        :valid="stepPayloads.columns !== null && stepPayloads.columns.deliverable.length > 0"
        :summary="makeStepSummary('columns')"
        simpleSummary
      )
        template(v-slot:content)
          NioDivider(horizontal-solo)
          ColumnsStep(
            :dataset="dataset"
            :current-step="currentStep"
            :deliverable="stepPayloads.columns ? stepPayloads.columns.deliverable : []"
            :filterable="stepPayloads.columns ? stepPayloads.columns.filterable : []"
            :completed-summary="makeStepSummary('columns')"
            @stepPayloadChanged="updatePayload('columns', $event)"
            @setStepIncomplete="setStepIncomplete('columns')"
          )
      NioStep(
        step-name="filters"
        :valid="isFiltersStepValid"
        :summary="makeStepSummary('filters')"
        simpleSummary
      )
        template(v-slot:content)
          NioDivider(horizontal-solo)
          FiltersStep(
            :filters="filters"
            :current-step="currentStep"
            :deduplication="deduplication"
            :deduplication-period-valid="deduplicationPeriodValid"
            :deduplication-format-valid="deduplicationFormatValid"
            :deduplication-paths-valid="deduplicationPathsValid"
            :hasFilterable="hasFilterable"
            :complete-summary="makeStepSummary('filters')"
            @stepPayloadChanged="updatePayload('filters', $event)"
            @setStepIncomplete="setStepIncomplete('filters')"
          )
      NioStep(
        :valid="stepPayloads.offers !== null && offersValid"
        :summary="makeStepSummary('offers')"
        step-name="offers"
        customSummary
      )
        template(v-slot:content)
          NioDivider(horizontal-solo)
          OffersStep(
            v-if="stepPayloads && stepPayloads.dataset && stepPayloads.columns"
            :current-step="currentStep"
            :dataset="stepPayloads.dataset"
            :columns="stepPayloads.columns"
            :completed-summary="makeStepSummary('offers')"
            @stepPayloadChanged="updatePayload('offers', $event)"
            @setStepIncomplete="setStepIncomplete('offers')"
          )
        template(v-slot:custom-summary)
          .offers-summary
            .offer(v-if="getActiveOffer('data_streams_market') && getActiveOffer('data_streams_market').value")
              .nio-h4.text-primary-darker Data Stream Marketplace
              .nio-h4.text-primary-darker {{ formatPrice(getActiveOffer('data_streams_market').value) }}
            .offer(v-if="getActiveOffer('data_shop') && getActiveOffer('data_shop').value")
              .nio-h4.text-primary-darker Data Shop
              .nio-h4.text-primary-darker {{ formatPrice(getActiveOffer('data_shop').value) }}
            .offer(v-if="getActiveOffer('Narrative TTD 3p Custom Connector') && getActiveOffer('Narrative TTD 3p Custom Connector').value")
              .nio-h4.text-primary-darker The TradeDesk Connector
              .nio-h4.text-primary-darker {{ ttdConnectorSummary(getActiveOffer('Narrative TTD 3p Custom Connector')) }}
      
      NioStep(
        :valid="true"
        :summary="makeStepSummary('buyers')"
        step-name="buyers"
        simpleSummary
      )
        template(v-slot:content)
          NioDivider(horizontal-solo)
          BuyersStep(
            v-if="stepPayloads && stepPayloads.dataset && stepPayloads.columns && stepPayloads.offers"
            :current-step="currentStep"
            :completed-summary="makeStepSummary('buyers')"
            @stepPayloadChanged="updatePayload('buyers', $event)"
            @setStepIncomplete="setStepIncomplete('buyers')"
          )
        template(v-slot:custom-summary)
          .offers-summary
            .offer(v-if="getActiveOffer('data_streams_market') && getActiveOffer('data_streams_market').value")
              .nio-h4.text-primary-darker Data Stream Marketplace
              .nio-h4.text-primary-darker {{ formatPrice(getActiveOffer('data_streams_market').value) }}
            .offer(v-if="getActiveOffer('data_shop') && getActiveOffer('data_shop').value")
              .nio-h4.text-primary-darker Data Shop
              .nio-h4.text-primary-darker {{ formatPrice(getActiveOffer('data_shop').value) }}
            .offer(v-if="getActiveOffer('Narrative TTD 3p Custom Connector') && getActiveOffer('Narrative TTD 3p Custom Connector').value")
              .nio-h4.text-primary-darker The TradeDesk Connector
              .nio-h4.text-primary-darker {{ ttdConnectorSummary(getActiveOffer('Narrative TTD 3p Custom Connector')) }}
            
      NioStep(
        :valid="descriptionStepValid"
        :summary="makeStepSummary('description')"
        step-name="description"
        customSummary
      )
        template(v-slot:custom-summary)
          .description-summary
            h3.nio-h3.text-primary-darker {{ dataStreamName }}
            p.nio-p.text-primary-dark(v-if="dataStreamDescription") {{ dataStreamDescription }}
            .tags(v-if="dataStreamTags")
              NioPill(
                tag
                v-for="tag of dataStreamTags"
              ) {{ tag }}
        template(v-slot:content)
          NioDivider(horizontal-solo)
          DescriptionStep(
            :current-step="currentStep"
            :columns="stepPayloads ? stepPayloads.columns : null"
            :completed-summary="makeStepSummary('description')"
            @stepPayloadChanged="updatePayload('description', $event)"
            @setStepIncomplete="setStepIncomplete('description')"
          )
      NioStep(
        :valid="allStepsComplete"
        :summary="makeStepSummary('review')"
        step-name="review"
        simple-summary
      )
        template(v-slot:content)
          NioDivider(horizontal-solo)
          ReviewStep(
            v-if="reviewPreview"
            :preview="reviewPreview"
            :completed-summary="makeStepSummary('review')"
            :forecast="forecastResults"
            :forecast-params-stale="forecastStale"
            @stepPayloadChanged="updatePayload('review', $event)"
            @setStepIncomplete="setStepIncomplete('review')"
          )
    NioDialog(
      v-model="destinationErrorDialog" 
    )
      ErrorDialog(
        error-description="Your Data Stream was created, but there was a problem with one or more of your configured destinations. Please contact a member of the Narrative team if you continue to experience this problem."
        @close="closeDestinationErrorDialog"
      )
</template>

<script>

import filtersModule from '../../modules/filtersModule'
import BuyersStep from './steps/buyers/BuyersStep'
import DatasetStep from './steps/dataset/DatasetStep'
import ColumnsStep from './steps/columns/ColumnsStep'
import FiltersStep from './steps/filters/FiltersStep'
import OffersStep from './steps/offers/OffersStep'
import DescriptionStep from './steps/description/DescriptionStep'
import ReviewStep from './steps/review/ReviewStep'
import ErrorDialog from './ErrorDialog'
import { NioOpenApiModule } from '@narrative.io/tackle-box'
import { formatCurrency } from '@/modules/helpers'
import { makeDataStream, makeDataRules } from './dataStream'
import { makeDestinations, ttdConnectorSummary } from './destinations'
import DeduplicationFilter from './deduplicationFilter'
export default {
  components: { 
    BuyersStep,
    DatasetStep, 
    ColumnsStep, 
    FiltersStep, 
    OffersStep, 
    DescriptionStep, 
    ReviewStep, 
    ErrorDialog 
  },
  data: () => ({
    steps: null,
    currentStep: null,
    completedSteps: [],
    filters: null,
    filtersValid: true,
    deduplication: null,
    deduplicationPeriodValid: true,
    deduplicationFormatValid: true,
    deduplicationPathsValid: false,
    dataset: null,
    datasets: null,
    reviewPreview: {
      name: null,
      imageUrl: null,
      category: null,
      offers: null
    },
    stepPayloads: {
      dataset: null,
      columns: null,
      filters: null,
      offers: null, 
      buyers: null,
      description: null,
      review: null
    },
    loading: true,
    numDatasets: null,
    destinationErrorDialog: false,
    descriptionStepValid: false,
    forecastParams: null,
    prevForecastParams: null,
    forecastStale: false, 
    openApiBaseUrl: null,
    openApiToken: null,
    forecastResults: null,
    costForecastResults: null
  }),
  computed: {
    allStepsComplete() {
      return this.completedSteps.includes('columns') && this.completedSteps.includes('filters') && this.completedSteps.includes('offers') && this.completedSteps.includes('description')
    },
    offersValid() {
      if (this.stepPayloads && this.stepPayloads.offers && this.stepPayloads.offers.length > 0) {
        const offers = this.stepPayloads.offers
        if (!offers.find(offer => offer.active && !offer.valid) && offers.find(offer => offer.active)) {
          return true
        }
      }
      return false
    },
    hasFilterable() {
      return this.stepPayloads?.columns?.filterable && this.stepPayloads?.columns?.filterable?.length > 0
    },
    isFiltersStepValid() {
      if (this.stepPayloads?.columns?.deliverable && this.stepPayloads?.columns?.deliverable?.length > 0 && !this.hasFilterable) {
        return true
      }
      return this.filtersValid && 
      (this.deduplication && this?.deduplication[0]?.value === 'default' || 
      (this.deduplicationFormatValid && this.deduplicationPeriodValid && this.deduplicationPathsValid))
    },
    dataStreamName() {
      return this.stepPayloads?.description?.model?.name
    },
    dataStreamDescription() {
      return this.stepPayloads?.description?.model?.description
    },
    dataStreamTags() {
      return this.stepPayloads?.description?.model?.tags
    }
  },
  watch: {
    filters: {
      deep: true,
      handler(val) {
        this.checkFiltersValid() 
      }
    },
    deduplication: {
      deep: true,
      handler(val) {
        this.checkDeduplication(val)
      }
    }
  },
  mounted() {
    NioOpenApiModule.initCallback(this.openApiInit)
  },
  methods: {
    openApiInit() {
      this.openApiBaseUrl = NioOpenApiModule.getBaseUrl()
      this.openApiToken = NioOpenApiModule.getToken()
      this.getDatasets().then(datasets => {
        this.datasets = datasets.filter(dataset => dataset.status === 'active' && this.isSupportedDataset(dataset))
        this.loading = false
        if (this.datasets.length === 1) {
          this.steps = ['columns', 'filters', 'offers', 'buyers', 'description', 'review']
          this.currentStep = 'columns'    
          this.stepPayloads.dataset = datasets[0]
          this.dataset = datasets[0]
        } else if (this.datasets.length > 1) {
          this.steps = ['dataset', 'columns', 'filters', 'offers', 'buyers', 'description', 'review']
          this.currentStep = 'dataset'
        } else {
          // show no datasets screen
        }
      })
    },
    getDatasets() {
      return new Promise((resolve,reject) => {
        this.$nioOpenApi.get('/datasets').then(res => {
          const datasets = res.data.records.filter(record => Object.keys(record.schema.properties).length > 0)
          resolve(datasets)
        })
      })
    },
    isSupportedDataset(dataset) {
      const properties = dataset.schema?.properties
      for (const property in properties) {
        if (properties[property].type === 'object' || properties[property].type === 'array') {
          return false;
        }
      }
      return true;
    },
    checkFiltersValid(filters) {
      this.$nextTick(() => {
        this.filtersValid = (this.filters && this.filters.length && this.filters.find(filter => filter.valid === false) === undefined)
        this.filtersValid = this.filtersValid === 0 ? false : this.filtersValid
        if (!this.filtersValid) {
          this.setStepIncomplete('filters')
        }
        this.makeDataStreamParams()
      }) 
    },
    checkDeduplication(val) {
      const validResult = filtersModule.deduplicationFilterValid(val[0])
      this.deduplicationFormatValid = validResult.formatValid
      this.deduplicationPeriodValid = validResult.periodValid
      this.deduplicationPathsValid = validResult.pathsValid
      this.makeDataStreamParams()
    },
    evaluateFilters(payload) {
      payload.forEach(filter => {
        switch (filter.type) {
          case 'stringLimited':
            if (!(filter.customOption.value.items.length > 0)) {
              filter.value = 'default'
            }
            break
          case 'stringMany':
            const items = filter.customOption.value.manualEntry.split("\n").map(val => val.trim())
            if (!(filter.customOption.value.manualEntry && items.length > 0)) {
              filter.value = 'default'
            }
            break
          case 'number':
            if (!(filter.customOption.value[0] || filter.customOption.value[1])) {
              filter.value = 'default'
            }
            break
          case 'simpleTimestamp':
            if (!(filter.customOption.value.start.enabled || filter.customOption.value.end.enabled)) {
              filter.value = 'default'
            }
            break
          case 'boolean':
            if (!(filter.customOption.value === true || filter.customOption.value === false)) {
              filter.value = 'default'
            }
            break
          default:
            break
        }
      })
    },
    updatePayload(step, payload) {
      this.stepPayloads[step] = payload
      if (step === 'dataset') {
        this.dataset = payload
        this.forecastResults = null
      } else if (step === 'columns') {
        this.stepPayloads.filters = null
        this.completedSteps = this.completedSteps.filter(step => step !== 'filters')
        this.filters = filtersModule.initFilters(payload.filterable)
        this.deduplication = filtersModule.makeDeduplicationFilter(JSON.parse(JSON.stringify(DeduplicationFilter)), this.stepPayloads.columns.deliverable)
        this.stepPayloads.filters = this.filters
        this.forecastResults = null
      } else if (step ==='filters'){
        if (!this.filtersValid) {
          this.stepPayloads.filters = null
        }
      } else if (step === 'offers') {
        this.reviewPreview.offers = this.stepPayloads.offers
        if (!this.offersValid) {
          this.setStepIncomplete('offers')
        }
      } else if (step === 'buyers') {
        // no buyer validations
      } else if (step === 'description') {
        this.reviewPreview = {
          name: this.stepPayloads.description.model.name,
          imageUrl: this.stepPayloads.description.model.image,
          category: this.stepPayloads.description.model.category,
          offers: this.stepPayloads.offers
        }
        if (payload && 
          payload.model.name && 
          payload.model.slug && 
          payload.model.description && 
          payload.model.name.length > 0 && 
          payload.model.slug.length > 0 && 
          payload.model.slug.length < 256 && 
          payload.model.description.length > 0 &&
          payload.slugValid
        ) {
          this.descriptionStepValid = true
        } else {
          this.descriptionStepValid = false
          this.setStepIncomplete('description')
        }
      }
    },
    nextStep() {
      if (!this.completedSteps.includes(this.currentStep)) {
        this.completedSteps.push(this.currentStep) 
      }
      if (this.currentStep === 'filters') {
        if (!this.stepPayloads.filters && this.filtersValid) {
          this.stepPayloads.filters = this.filters
        } else {
          this.evaluateFilters(this.stepPayloads.filters)
        }
      }
      this.currentStep = this.steps[this.steps.indexOf(this.currentStep) + 1]
      this.scrollToStep(this.steps.indexOf(this.currentStep))
    },
    previousStep() {
      this.currentStep = this.steps[this.steps.indexOf(this.currentStep) - 1]
      this.scrollToStep(this.steps.indexOf(this.currentStep))
    },
    stepSelected(stepName) {
      this.currentStep = stepName
    },
    setStepIncomplete(stepName) {
      const stepIndex = this.completedSteps.indexOf(stepName)
      this.completedSteps = this.completedSteps.filter((step, index) => {
        if (stepName === 'filters' || stepName === 'description' || stepName === 'offers') {
          return step !== stepName
        } else {
          return index < stepIndex
        }
      })
      this.steps.map((step, index) => {
        if (index >= stepIndex && stepName !== 'filters' && stepName !== 'description' && stepName !== 'offers') {
          if (step !== 'description' && step !== 'filters') {
            this.stepPayloads[step] = null
          }
        }
      })
    },
    formatPrice(price) {
      return formatCurrency(price)
    },
    stepComplete(stepName) {
      return this.completedSteps.includes(stepName)
    },
    makeStepSummary(stepName) {
      if (!this.stepPayloads[stepName]) {
        return
      }
      switch (stepName) {
        case 'dataset':
          return {
            title: `Dataset #${this.stepPayloads.dataset.id}: ${this.stepPayloads.dataset.display_name}`
          }
        case 'columns': 
          return {
            title: `${this.stepPayloads.columns.deliverable.length} ${this.stepPayloads.columns.deliverable.length === 1 ? 'column' : 'columns'}`
          }
        case 'filters':
          const numFilters = this.filters.filter(filter => filter.value !== 'default').length
          return {
            title: `${numFilters === 0 ? 'Include all data' : `${numFilters} ${numFilters === 1 ? 'filter' : 'filters'}`}`
          }
        case 'buyers':
          if (this.stepPayloads.buyers?.constraintType === "inclusion") {
            return {
              title: `Custom: Include ${this.stepPayloads.buyers?.companyIds.length} buyers`
            }
          } else if (this.stepPayloads.buyers?.constraintType === "exclusion") {
            return {
              title: `Custom: Exclude ${this.stepPayloads.buyers?.companyIds.length} buyers`
            }
          } else {
            return {
              title: `Sell to any buyer`
            }
          }
        case 'offers':
        case 'description': 
        case 'review':
        default:
          return {
            title: null
          }
      }
    },
    ttdConnectorSummary(offer) {
      return ttdConnectorSummary(offer)
    },
    makeDataStreamParams() {
      this.$nextTick(() => {
        const deduplication = this.hasFilterable && this.deduplication ? this.deduplication : {}
        const params = makeDataRules(
          {
            ...this.stepPayloads,
            filters: this.filters,
          },
          deduplication
        )
        const updated = JSON.stringify(this.prevDataStreamParams) !== JSON.stringify(params)  
        if (params && updated) {
          this.prevDataStreamParams = params
          console.log("DATA STREAM DETAILS:")
          if (!this.filtersValid) {
            this.forecastParams = null
            if (this.nioUser?.role === 99) {
              console.log("Filters are invalid.")
            }  
          } else {
            this.forecastParams = {
              type: "data_stream_creation",
              data_rules: {
                ...params,
                attributes: null,
                frequency_filter: null,
                dataset_filter: null,
                ingestion_timestamp_filter: null
              }
            }
            console.log(this.forecastParams.data_rules)
            if (JSON.stringify(this.forecastParams) !== JSON.stringify(this.prevForecastParams)) {
              this.forecastStale = true
            } else {
              this.forecastStale = false
            }
          }     
        }
      }) 
    },
    async createDataStream() {
      this.checkDeduplication(this.deduplication)
      const newDataStream = makeDataStream(this.stepPayloads, this.dataset, this.deduplication, this.stepPayloads.buyers)
      this.loading = true
      parent.postMessage({
        name: 'scrollTo',
        payload: {
          x: 0,
          y: 0
        }
      },"*")
      this.$nioOpenApi.post(`/data-stream`, JSON.stringify(newDataStream)).then(async res => {
        if (res.status === 200) {
          try {
            const destinations = makeDestinations(res.data, this.stepPayloads.offers)
            await Promise.all(destinations.map(async destination => {
              if (destination.createFnPromise) {
                return await destination.createFnPromise.call(this, destination.payload, this.$nioOpenApi)
              } else {
                return this.$nioOpenApi.post(destination.apiUrl, JSON.stringify(destination.payload))
              }
            }))
          } catch (e) {
            this.destinationErrorDialog = true
            console.log(e)
          }    
          try {
            parent.postMessage({
              name: 'hubspotAnalyticsEvent',
              payload: {
                eventName: 'sellerStudioDataStreamCreated',
                params: {
                  dataStreamName: res.data.name,
                  dataStreamSlug: res.data.slug,
                  date: res.data.created_at
                }
              }
            }, "*")
          } catch(e) {
            console.log(e)
          }
          if (!this.destinationErrorDialog) {
            parent.postMessage({
              name: 'pageNavigation',
              payload: 'data-streams'
            }, "*")
          }
        } 
        this.loading = false
      }, err => {
        this.loading = false
        this.handleError(err)
      })
    },
    handleError(err) {
      if (String(err).indexOf('409') > -1) {
        this.$router.push({name: 'error', params: {error: 'The chosen slug is already in use"'}})
      }
      this.$router.push({name: 'error', params: {error: JSON.stringify(err)}})
    },
    scrollToStep(stepIndex) {
      this.$nextTick(() => {
        const top = stepIndex === 0 || stepIndex === 1 ? 35 + stepIndex * 130 : 250 + stepIndex * 130
        parent.postMessage({
          name: 'scrollTo',
          payload: {
            x: 0,
            y: top
          }
        },"*")
      })       
    },
    learnDatasets() {
      window.open('https://app-dev.narrative.io/apps/dataset-manager', '_blank')
    },
    getActiveOffer(offerName) {
      return this.stepPayloads && this.stepPayloads.offers ? this.stepPayloads.offers.find(offer => offer.name === offerName && offer.active) : null
    },
    forecastStarted() {
      this.prevForecastParams = this.forecastParams
      this.forecastStale = false
      this.forecastResults = null
    },
    forecastComplete(results) {
      this.forecastResults = results
    },
    closeDestinationErrorDialog() {
      this.destinationErrorDialog = false
      parent.postMessage({
        name: 'pageNavigation',
        payload: 'data-streams'
      }, "*")
    }
  }
}
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.new-page
  padding: 1.5rem
  .header
    display: flex
    justify-content: center
    align-items: flex-start
    position: relative
    margin-bottom: 2rem
    .nio-button
      position: absolute
      right: 2.5rem
  .no-datasets
    padding: 9.6875rem 1.5rem 11.1875rem 1.5rem
    background-color: $c-canvas
    border: 0.0625rem solid $c-primary-lighter
    border-radius: 0.75rem
    display: flex
    flex-direction: column
    align-items: center
    .nio-icon-framer
      margin-bottom: 1rem
    h3
      margin-bottom: 0.5rem
    p    
      margin-bottom: 2.5rem
  .nio-forecast-widget
    margin-bottom: 1.5rem
    display: none !important
    &.visible
      display: block !important
  ::v-deep .nio-step-header-slat
    padding-top: 1.375rem
  .nio-divider
    margin-top: -1.25rem
  ::v-deep .v-expansion-panel-content__wrap
    padding: 0rem    
  ::v-deep .nio-step-content-body
    position: relative
    .creating-subscription
      width: 100%
      height: 100%
      position: absolute
      .v-progress-circular
        position: relative
        left: 50%
        top: 6.25rem
        margin-left: -2.5rem
        z-index: 2
  .description-summary
    display: flex
    flex-direction: column
    p
      margin-top: 0.5rem
    .tag
      margin-right: 0.5rem
  .nio-step-name-offers
    ::v-deep .nio-summary-slat
      padding: 0rem
    ::v-deep .offers-summary
      width: 100%
      width: 40rem
      .offer
        padding: 1rem 1.5rem
        display: flex
        justify-content: space-between
        align-items: center
      .offer + .offer
        border-top: 0.0625rem solid $c-primary-lighter
      .stacked-details
        display: flex
        flex-direction: column
        align-items: flex-end
        .stacked-detail
          display: flex
          justify-content: space-between
          .label
            display: flex
            justify-content: flex-end
          .value
            width: 4.375rem
            display: flex
            justify-content: flex-end
        & > .stacked-detail + .stacked-detail
          margin-top: 0.25rem

</style>
<template lang="pug">
  .offers-step
    .header.step-header
      h2.nio-h2.text-primary-darker Set Offers
      p.nio-p.text-primary-dark Offers describe the channels where the data stream can be purchased and the terms of purchase
    .step-loading(v-if="loading")
      v-progress-circular.progress(
        size="80" 
        color="#1438F5"
        indeterminate 
      )
    .offers(v-if="destinations && !loading")
      NioDataStreamOffers(
        :key="offersKey"
        :destinations="destinations"
        :ttd-taxonomy="ttdTaxonomy"
        @offersChanged="offersUpdated"
      )
    .validation-error
      p.nio-p.text-error {{ errorMsg }}
</template>

<script>

const RateTypeToItemKeyMapping = { // TODO use TB taxonomyModule
  "Partner": "partnerRateCard",
  "Advertiser": "advertiserRateCard",
  "System": "systemRateCard"
}

import { NioOpenApiModule } from '@narrative.io/tackle-box'
import { makeRandomId } from '@narrative.io/tackle-box/src/modules/helpers'
import { getExistingTTDTaxonomy } from '@narrative.io/tackle-box/src/modules/app/ttd-taxonomy/ttdTaxonomyModule'
import { baseUrl, setHeaders, getHeaders } from '@/utils/ttdServiceLayer'

export default {
  props: {
    "completedSummary": { type: Object, required: false },
    "dataset": { type: Object, required: true },
    "columns": { type: Object, required: true }
  },
  data: () => ({
    destinations: null,
    ttdTaxonomy: null,
    loading: false,
    offers: null,
    offersKey: null
  }),
  computed: {
    noneSelected() {
      return !this.offers.find(offer => offer.active)
    },
    valid() {
      return !this.offers.find(offer => offer.active && !offer.valid)
    },
    errorMsg() {
      if (this.offers && this.noneSelected) {
        return 'At least one offer must be selected'
      } else {
        return ''
      }
    }
  },
  watch: {
    datasets: { 
      deep: true, 
      handler () {
        this.getDestinations()
      }
    },
    columns: { 
      deep: true, 
      handler () {
        this.getDestinations()
      }
    }
  },
  mounted() {
    NioOpenApiModule.initCallback(this.openApiInit)
  },
  methods: {
    async openApiInit(token) {
      setHeaders(token)
      this.getDestinations()
    },
    offersUpdated(offers) {
      this.offers = offers
      this.emitPayload()
    },
    emitPayload() {
      this.$emit('stepPayloadChanged', this.offers)
    },
    async getDestinations() {
      this.destinations = null
      this.loading = true
      let uniqueFields = [...this.columns.filterable, ...this.columns.deliverable].reduce((acc, field) => {
        if (!acc.find(currentField => currentField.name === field.name)) {
          acc.push(field)
        }
        return acc
      }, [])
      const [destinationsResp, ttdTaxonomyResp] = await Promise.all([
        this.$nioOpenApi.post('/data-stream/destinations',{
          dataset_id: this.dataset.id,
          fields: uniqueFields.map(field => {
            return {
              field: field.name,
              exported: this.columns.deliverable.find(deliverableField => deliverableField.name === field.name) ? true : false
            }
          })
        }),
        getExistingTTDTaxonomy(getHeaders(), baseUrl, this.nioUser.companyId, this.$nioOpenApi)
      ])
      this.offersKey = makeRandomId()
      if (ttdTaxonomyResp && ttdTaxonomyResp.length > 0) {
        this.ttdTaxonomy = ttdTaxonomyResp
      }
      this.destinations = destinationsResp.data && destinationsResp.data.records ? destinationsResp.data.records : []
      this.loading = false
    }
  }
}
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"
@import "@narrative.io/tackle-box/src/styles/mixins/filter/_filter-header"
@import "@narrative.io/tackle-box/src/styles/mixins/slat/_slat-group"

.offers-step
  .step-header
    position: relative
  .offers
    .offer
      display: flex
      align-items: center
      .offer-content
        display: flex
        flex-direction: column
        justify-content: center
        align-items: flex-start
        margin-left: 1.5rem
        .nio-p
          margin-bottom: 0rem
  .validation-error
    width: 100%
    height: 1.25rem
    margin-top: 1.5rem
    p
      text-align: center
</style>

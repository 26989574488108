const baseUrl = window.location.host === 'seller-studio.narrative.io' ?
  'https://thetradedesk.narrativeconnectors.com' :
  'https://thetradedesk-dev.narrativeconnectors.com'

let headers = null

function setHeaders(token) {
  headers = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }
}

function getHeaders() {
  return headers
}

export {
  baseUrl,
  setHeaders,
  getHeaders
}

